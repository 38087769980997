import styles from "./ShowMyProduct.module.scss";
import React, { useEffect, useState } from "react";
import { ProductInfo } from "components";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { axiosProduct } from "plugins/axios";
import { useNavigate } from "react-router-dom";

const ShowMyProduct = () => {
  const navigate = useNavigate();
  const [product, setProduct] = useState({});

  const params = useParams();
  const getProduct = async () => {
    let result = await axiosProduct.getProduct(params.id);
    setProduct(result.info || {});
  };

  useEffect(() => {
    getProduct();

    let history = localStorage.getItem("history")
      ? JSON.parse(localStorage.getItem("history"))
      : [];
    if (!history.find((el) => el == params.id)) {
      history = [params.id, ...history];
    }
    localStorage.setItem("history", JSON.stringify(history.slice(0, 4)));
  }, [params.id]);

  return (
    <div className={styles.container}>
      <Helmet>
        <title>{product.name ? "Товар " + product.name : "Товар"}</title>

        <meta name="robots" content="noindex" />
        <meta
          name="description"
          content={
            "Shalfey : " +
            product.name +
            ". " +
            String(product.description).substring(0, 50)
          }
        />
        <link rel="canonical" href={`/product/${params.id}`} />
      </Helmet>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "4vh",
          width: "79%",
        }}
      >
        <button className={styles.cancel} onClick={() => {}}>
          Назад
        </button>
        <button
          className={styles.further}
          onClick={() => navigate(`/seller/upduteproduct/${params.id}`)}
        >
          Редактировать
        </button>
      </div>
      <div className={styles.section}>
        <ProductInfo product={product} />
      </div>
    </div>
  );
};

export { ShowMyProduct };
